:root {
  --color-main-background: #b2bfcb;
  --color-white: #ffffff;
  --color-gray: #eeeeee;
  --color-blue: #1976d2;
  --color-error: #d32f2f;
  --color-error-semitransparent: rgba(211, 47, 47, 0.5);
  --color-error-quarter-transparent: rgb(253, 237, 237);
  --color-warning: #ed6c02;
  --color-warning-semitransparent: rgba(237, 108, 2, 0.5);
  --color-success: #2e7d32;
  --color-success-semitransparent: rgba(46, 125, 50, 0.5);
  --color-success-quarter-transparent: rgb(237, 247, 237);
  --color-info: #0288d1;
  --color-info-semitransparent: rgba(2, 137, 209, 0.5);
}

body {
  min-height: 100vh;
  font-family: sans-serif;
}

.root {
  min-height: 100vh;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}
.MuiDataGrid-main {
  background-color: var(--color-white);
}
/* Change table head cell styling for better visibility*/
.MuiDataGrid-columnHeaderTitle {
  font-size: 0.75rem;
  font-weight: bold; /* TODO fix after font change */
}

/* This fixes not full MUI input file field clickable for open system file dialog */
.fix-full-with-click .MuiInputBase-adornedEnd {
  padding-left: 0;
  padding-right: 0;
}
.fix-full-with-click .MuiInputBase-adornedEnd label {
  width: 100%;
}
.fix-full-with-click .MuiInputBase-adornedEnd label span {
  padding-left: 44px;
}
.fix-full-with-click .MuiInputBase-adornedEnd .MuiInputAdornment-positionEnd {
  position: absolute;
  right: 0;
  z-index: 2;
}
.fix-full-with-click .MuiTypography-caption {
  pointer-events: none;
}
.fix-full-with-click .MuiInputBase-adornedStart .MuiInputAdornment-positionStart {
  position: absolute;
  left: 12px;
  pointer-events: none;
}
/* Add cursor pointer to modal backdrop */
.MuiModal-root .MuiBackdrop-root {
  cursor: pointer;
}
.MuiModal-root .MuiBox-root {
  cursor: default;
}
/* more neat icon placement */
.MuiButton-sizeSmall .MuiButton-startIcon.MuiButton-iconSizeSmall {
  transform: translate(0, -1px);
  margin-right: 4px;
}
.MuiButton-outlined {
  background-color: var(--color-white);
}
.MuiToggleButton-root {
  background-color: var(--color-white);
}
/* make tables neat */
.MuiTableCell-root {
  padding-left: 0;
  padding-right: 0;
}
.MuiTableCell-root + .MuiTableCell-root:not(.MuiTableCell-compact) {
  padding-left: 16px;
}

.MuiOutlinedInput-root {
  background-color: var(--color-white);
}

.MuiInputBase-root.Mui-disabled {
  background: none;
  cursor: not-allowed;
  color: currentColor;
}
.MuiInputBase-root.Mui-disabled input,
.MuiInputBase-root.Mui-disabled textarea {
  cursor: not-allowed;
  -webkit-text-fill-color: currentColor;
}

.MuiDataGrid-root--densityCompact .MuiDataGrid-columnHeader:not(:last-child),
.MuiDataGrid-root--densityCompact .MuiDataGrid-cell:not(:last-child) {
  padding-right: 0;
}

/* For any drawer over popover the same z-index as popover to overlay */
.MuiPopover-root ~ .MuiDrawer-root {
  z-index: 1300;
}

/* Rework grouped table borders */
.MuiTable-root-grouped,
.MuiTable-root-grouped .MuiTableCell-root {
  border-color: rgb(224, 224, 224);
}
.MuiTable-root-grouped .MuiTableCell-weak {
  border-left-color: rgb(245, 245, 245);
}
.MuiTable-root-grouped .MuiTableRow-weak .MuiTableCell-root {
  border-bottom-color: rgb(245, 245, 245);
}
.MuiTable-root-grouped .MuiTableRow-strong .MuiTableCell-root {
  border-bottom-color: rgb(189, 189, 189);
}

.MuiTableCell-compact {
  padding-top: 0;
  padding-bottom: 0;
}

.MuiTable-root-grouped .MuiTableCell-root:first-child {
  padding-left: 8px;
}

.MuiButton-sizeMedium,
.MuiToggleButton-sizeMedium {
  min-height: 40px;
}

.MuiSkeleton-loading-button {
  position: absolute;
  inset: 0;
  transform: none;
  background: none;
}
.MuiSkeleton-contrast::after {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.15), transparent);
}
.MuiButton-outlined .MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(25, 118, 210, 0.38), transparent);
}
.Mui-disabled.MuiButton-contained.Mui-loading {
  background: var(--color-blue);
  color: white;
}
.Mui-disabled.MuiButton-contained.MuiButton-colorError.Mui-loading { 
  background: var(--color-error);
}
.Mui-disabled.MuiButton-contained.MuiButton-colorWarning.Mui-loading { 
  background: var(--color-warning);
}
.Mui-disabled.MuiButton-contained.MuiButton-colorSuccess.Mui-loading { 
  background: var(--color-success);
}
.Mui-disabled.MuiButton-contained.MuiButton-colorInfo.Mui-loading { 
  background: var(--color-info);
}
.Mui-disabled.MuiButton-outlined.Mui-loading {
  background: white;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: var(--color-blue);
}
.Mui-disabled.MuiButton-outlined.MuiButton-colorError.Mui-loading {
  border-color: var(--color-error-semitransparent);
  color: var(--color-error);
}
.MuiButton-outlined.MuiButton-colorError .MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, var(--color-error-semitransparent), transparent);
}
.Mui-disabled.MuiButton-outlined.MuiButton-colorWarning.Mui-loading {
  border-color: var(--color-warning-semitransparent);
  color: var(--color-warning);
}
.MuiButton-outlined.MuiButton-colorWarning .MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, var(--color-warning-semitransparent), transparent);
}
.Mui-disabled.MuiButton-outlined.MuiButton-colorSuccess.Mui-loading {
  border-color: var(--color-success-semitransparent);
  color: var(--color-success);
}
.MuiButton-outlined.MuiButton-colorSuccess .MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, var(--color-success-semitransparent), transparent);
}
.Mui-disabled.MuiButton-outlined.MuiButton-colorInfo.Mui-loading {
  border-color: var(--color-info-semitransparent);
  color: var(--color-info);
}
.MuiButton-outlined.MuiButton-colorInfo .MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, var(--color-info-semitransparent), transparent);
}
.notistack-MuiContent > div {
  width: 100%;
}
.MuiDataGrid-row.MuiDataGrid-row-error {
  background-color: var(--color-error-quarter-transparent);
}
.MuiDataGrid-row.MuiDataGrid-row-success {
  background-color: var(--color-success-quarter-transparent);
}
.number {
  font-family: monospace;
  font-size: 0.97em;
}
.MuiChip-labelSmall {
  transform: translate(0, 1px);
}
.MuiChip-status {
  position: relative;
  padding-left: 12px;
}
.MuiChip-status::before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 3px solid;
  border-color: inherit;
  border-radius: 50%;
  left: 8px;
}
/* Allow multiline for Chip */
.MuiChip-multiline {
  height: auto;
}
.MuiChip-multiline .MuiChip-label {
  display: block;
  white-space: normal;
}
/* shows separator between buttons */
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-lastButton,
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton {
  margin-left: 0;
}
/* Make menu more compact */
.side-menu .MuiMenuItem-root {
  padding: 6px 12px;
}
.side-menu .MuiListItemIcon-root {
  min-width: 32px;
}
.side-menu .MuiListItemText-primary {
  font-size: 0.95rem;
}
/* Badge neat */
.MuiBadge-badge {
  font-family: monospace;
}
.MuiButton-root ~ .MuiBadge-anchorOriginTopRight {
  top: 1px;
  right: 1px;
}
/* not found page svg */
.not-found {
  overflow: hidden;
  background: black;
  width: 100vw;
  height: 100vh;
  position: relative;
}
.not-found-background {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.not-found-scene {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.not-found-warning {
  position: absolute;
  top: 24%;
  left: 15%;
  font-size: 24px;
  color: white;
  font-family: monospace;
}
.not-found-warning a {
  color: blue;
}

/* all buttons by default haven't stretched height */
.MuiButton-root {
  align-self: center;
}

/* Since all columns are not sortable by default we want to highlight sort icons */
.MuiDataGrid-columnHeader--sortable .MuiDataGrid-iconButtonContainer {
  width: auto;
  visibility: visible;
}
.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon {
  opacity: 0.5;
}
.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon {
  opacity: 1;
}

/* more neat dropdown options */
.MuiAutocomplete-groupLabel {
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.5;
  padding: 6px 2px 6px 12px;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 14px;
  padding: 4px 2px 4px 16px;
}
/* Sometimes we need to pase html from the backend and want p tags be neat */
.MuiTypography-root p {
  margin: 0;
}
.MuiTypography-root p + p {
  margin-top: .625em;
}